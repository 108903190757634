import { render, staticRenderFns } from "./detailCoupon.vue?vue&type=template&id=4da8989d&scoped=true"
import script from "./detailCoupon.vue?vue&type=script&lang=js"
export * from "./detailCoupon.vue?vue&type=script&lang=js"
import style0 from "./detailCoupon.vue?vue&type=style&index=0&id=4da8989d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4da8989d",
  null
  
)

export default component.exports